import Modal from "./modules/modal.js"; //quite
import Tabs from "./modules/tabs.js"; //quite
import objectFitImages from "./extern/ofi.min.js"; //quite

var app = new Vue({
  el: "#app",
  data: {
    name: ""
  },
  mounted() {
    if (localStorage.name) {
      this.name = localStorage.name;
    }
  },
  watch: {
    name(newName) {
      localStorage.name = newName;

      const input = this.$refs.input;

      if (input) {
        if (newName === "") {
          input.style.width = "auto";
        } else {
          input.style.width = (newName.length + 1) * 45 + "px";
        }
      }
    }
  }
});

//Touch sniffer
if ("ontouchstart" in document.documentElement) {
  document.querySelector("html").classList.add("is-touch");
}

// Hamburger menu
var $body = document.querySelector("body");
var $hamburger = document.querySelector(".site-hamburger");

$hamburger &&
  $hamburger.addEventListener("click", function(e) {
    e.stopPropagation();
    $body.classList.toggle("nav-open");
  });

$body.addEventListener("click", function() {
  if( $body.classList.contains('nav-open') ) {
    $body.classList.remove("nav-open");
  }
});

const achorLinks = document.querySelectorAll('.site-menu a[href^="#"]');
[...achorLinks].forEach( achor => {
  achor.addEventListener( 'click', closeNav );
})

function closeNav() {
  $body.classList.remove("nav-open");
}

const $toTop = document.querySelector(".backtotop");
window.addEventListener("scroll", function(e) {
  let top = window.pageYOffset;
  top > 100
    ? $body.classList.add("is-scroll")
    : $body.classList.remove("is-scroll");
  top > 800 ? $toTop.classList.add("vis") : $toTop.classList.remove("vis");
});

if( document.querySelector( '.jobs-swiper' ) ) {

  var jobsSwiper = new Swiper(".jobs-swiper", {
    // Optional parameters
    loop: true,
    slidesPerView: 1,
    spaceBetween: 30,
    effect: "fade",

    // Navigation arrows
    navigation: {
      nextEl: ".swiper-next",
      prevEl: ".swiper-prev"
    }
  });

}

function startingSlide(wrapper, slide = 0) {
  const slides = document.querySelector(`${wrapper} .swiper-wrapper`).childElementCount;
  const start = slide === 0 ? slides : slides - slide;

  console.log(start);

  return start;
}

if( document.querySelector( '.history-swiper' ) ) {

  var historySwiper = new Swiper(".history-swiper", {
    // Optional parameters
    loop: false,
    slidesPerView: 1,
    spaceBetween: 30,
    initialSlide: startingSlide(".history-swiper", 2),

    // Navigation arrows
    navigation: {
      nextEl: ".swiper-next",
      prevEl: ".swiper-prev"
    },
    breakpoints: {
      768: {
        slidesPerView: 2
      },
      1024: {
        slidesPerView: 3
      }
    }
  });
}

if( document.querySelector( '.inhouse-swiper' ) ) {

  var inhouseSwiper = new Swiper(".inhouse-swiper", {
    // Optional parameters
    loop: true,
    slidesPerView: 2,
    spaceBetween: 10,
    centeredSlides: true,
    autoplay: {
      delay: 1000,
      disableOnInteraction: true
    },
    breakpoints: {
      768: {
        slidesPerView: 3
      },
      1024: {
        slidesPerView: 4
      }
    }
  });

}

jQuery(document).ready(function($) {
  const inputs =
    ".gfield input:not([type=checkbox]), .gfield textarea, .gfield select";

  $("body").on("focusin focusout", inputs, function(event) {
    if (event.type === "focusin") {
      $(this)
        .parent()
        .parent()
        .addClass("gfield--active");
    } else if (event.type === "focusout") {
      if ($(this).val() === "") {
        $(this)
          .parent()
          .parent()
          .removeClass("gfield--active");
      }
    }
  });

  $(document).on("gform_post_render", function() {
    $(inputs).each(function() {
      let $that = $(this);

      if ($that.val() !== "") {
        $that
          .parent()
          .parent()
          .addClass("gfield--active");
      }
    });
  });

  objectFitImages();

  new Modal();

  if (document.querySelector("[data-tabs]")) {
    new Tabs();
  }
});
/**
 * New documents setup
 */
jQuery(document).ready(function($) {
    const inputs =
        ".searchandfilter input:not([type=checkbox]), .searchandfilter textarea, .searchandfilter select";

    $("body").on("focusin focusout", inputs, function(event) {
        if (event.type === "focusin") {
            $(this)
                .parent()
                .parent()
                .addClass("gfield--active");
        } else if (event.type === "focusout") {
            if ($(this).val() === "") {
                $(this)
                    .parent()
                    .parent()
                    .removeClass("gfield--active");
            }
        }
    });
});
